import React from 'react';
import PropTypes from 'prop-types';

import footerLogo from '../../static/images/footer-logo.png';

import { footerLinks, navBarLinks, socialLinks } from '../constants';
import { Link } from 'gatsby';
import IconComponent from './IconComponent';

function Footer({ location }) {
  const socialIcons = [
    {
      href: socialLinks.facebook,
      icon: 'facebook-filled',
      alt: 'facebook icon',
    },
    {
      href: socialLinks.instagram,
      icon: 'instagram-filled',
      alt: 'instagram icon',
    },
    { href: socialLinks.twitter, icon: 'twitter', alt: 'twitter icon' },
    {
      href: socialLinks.youtube,
      icon: 'youtube-icon-filled',
      alt: 'youtube icon',
    },
  ];

  let donateUrl = '/donate?utm_content=footer&amp;utm_source=website';
  if (location.search.includes('utm')) {
    donateUrl = '/donate' + location.search;
  }

  return (
    <footer>
      <div className="w-full h-24 text-lg bg-footer-dark text-neutral font-regular md:text-2xl">
        <div className="flex flex-col items-center justify-center w-full h-full md:flex-row">
          <div className="text-center">To Be a Part of this Sadaqa Jariah,</div>
          <Link to={donateUrl}>
            <span className="text-secondary md:ml-4 link-underline-white">
              Donate Now
            </span>
          </Link>
        </div>
      </div>
      <div className="w-full bg-footer">
        <div className="flex justify-center">
          <div className="w-full max-w-6xl p-6 md:grid md:grid-cols-3 pt-9 md:gap-16 lg:gap-36">
            <div className="mb-10 md:mb-0">
              <img src={footerLogo} className="w-32 h-auto" alt="brand logo" />
              <div className="mt-4 text-sm leading-relaxed font-regular text-neutral max-w-72">
                Greentech Apps Foundation is a UK based charity providing
                Islamic apps for bringing people closer to Allah, expecting
                rewards from Allah Subhanahu wa ta&apos;ala alone and following
                the tenets of Ahlus Sunnah wal Jama&apos;ah
              </div>
            </div>

            <div className="mb-10 md:mb-0">
              <div className="text-base text-neutral font-semiBold">
                Greentech Apps Foundation
              </div>
              <div className="flex mt-4 text-sm leading-relaxed font-regular text-neutral">
                <div className="pt-3.5">
                  {navBarLinks.map((item) => (
                    <div className="mb-3.5" key={item.title}>
                      <Link
                        className="no-underline link-underline-white"
                        to={item.menu ? item.menu[0].route : item.route}
                      >
                        {item.title}
                      </Link>
                    </div>
                  ))}
                </div>
                <div className="pt-3.5 ml-20">
                  {footerLinks.map((item) => (
                    <div className="mb-3.5" key={item.title}>
                      <Link
                        className="no-underline link-underline-white"
                        to={item.route}
                      >
                        {item.title}
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div>
              <div className="text-base text-neutral font-semiBold">
                Follow us
              </div>
              <div className="mt-4 flex pt-3.5">
                {socialIcons.map(({ href, icon, alt }, index) => (
                  <a
                    key={index}
                    className="mr-6 text-neutral hover:text-neutral-2"
                    href={href}
                    target="_blank"
                    rel="noreferrer"
                    alt={alt}
                  >
                    <IconComponent name={icon} />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-6xl p-6 text-xs text-center text-neutral font-regular md:text-left">
            <div>{`Greentech Apps Foundation © ${new Date().getFullYear()}`}</div>
            <div className="mt-1">UK Charity No. 1178251</div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

Footer.propTypes = {
  location: PropTypes.object,
};
