import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from './FormControl';

const CannyMessage = () => (
  <div className="px-6 py-8 mb-4 border rounded-md border-primary-1 bg-primary-0">
    Please submit your feature or bug requests to{' '}
    <a
      href="https://feedback.gtaf.org"
      target="_blank"
      rel="noreferrer"
      className="link-underline-primary text-primary"
    >
      feedback.gtaf.org
    </a>
    .
  </div>
);

const Notification = ({ success, message, onClose }) => (
  <div
    className={`w-full mb-6 mt-2 rounded-md p-6 h-20 ${
      success ? 'bg-primary-2' : 'bg-danger'
    }`}
  >
    {message}
    <button onClick={onClose} type="button" className="float-right">
      x<span className="sr-only">close</span>
    </button>
  </div>
);

Notification.propTypes = {
  success: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ContractForm = ({
  formInputFields,
  formData,
  setFormData,
  onSuccessResponse,
  disabledSubjectSelection = false,
}) => {
  const [notification, setNotification] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isFeatureOrBugRequest = () => {
    return (
      formData.subject_type &&
      ['feature', 'bug'].includes(formData.subject_type)
    );
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const fullSubject = `[${formData.subject_type}] ${formData.subject_text} by ${formData.email}`;

    try {
      const response = await fetch(
        'https://bfu04phfyd.execute-api.us-east-2.amazonaws.com/Prod/send',
        {
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
          body: JSON.stringify({
            toEmails: ['support@gtaf.org'],
            replyToEmails: [formData.email],
            subject: fullSubject,
            message: formData.message,
            name: formData.name,
          }),
        },
      );

      if (response.ok) {
        onSuccessResponse();
      } else {
        throw new Error('Failed to send');
      }
    } catch (error) {
      setIsSubmitting(false);
      setNotification({
        success: false,
        message: 'Request Failed! Please try again later.',
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <form onSubmit={onFormSubmit}>
      {Object.entries(formInputFields)
        .filter(([key]) => !isFeatureOrBugRequest() || key !== 'message')
        .map(([key, item]) =>
          key !== 'subject' ? (
            <FormControl
              key={key}
              data={item}
              value={formData[item.name] || ''}
              placeholder={item.placeholder}
              onChange={handleInputChange}
            />
          ) : (
            <div className="font-regular" key={key}>
              <label className="my-2 font-regular" htmlFor={key}>
                {item.label}
                {item.required && <sup className="text-secondary">*</sup>}
              </label>
              <div className="w-full mt-2 mb-6 md:flex">
                <select
                  disabled={disabledSubjectSelection}
                  className="relative w-full h-20 p-6 rounded-md border-neutral-5 form-select md:mr-2 md:w-auto"
                  value={formData.subject_type || ''}
                  required={item.required}
                  onChange={handleInputChange}
                  name="subject_type"
                >
                  <option value="" disabled>
                    - Select Type -
                  </option>
                  {item.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <input
                  className="w-full h-20 p-6 mt-2 rounded-md border-neutral-5 md:inline-flex md:flex-grow md:w-auto md:mt-0"
                  type="text"
                  placeholder={item.placeholder}
                  name="subject_text"
                  value={formData.subject_text || ''}
                  required={item.required}
                  onChange={handleInputChange}
                />
              </div>
              {isFeatureOrBugRequest() && <CannyMessage />}
            </div>
          ),
        )}
      {notification && (
        <Notification
          success={notification.success}
          message={notification.message}
          onClose={() => setNotification(null)}
        />
      )}
      {!isFeatureOrBugRequest() && (
        <button
          className="w-full h-20 p-6 mt-2 mb-6 rounded-md bg-secondary font-semiBold text-neutral-0"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Send'}
        </button>
      )}
    </form>
  );
};

ContractForm.propTypes = {
  formData: PropTypes.object.isRequired,
  formInputFields: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onSuccessResponse: PropTypes.func.isRequired,
  disabledSubjectSelection: PropTypes.bool,
};

export default ContractForm;
