import React from 'react';
import BoldTitle from './BoldTitle';

const CollaborateHeroSection = () => (
  <div className="flex justify-center w-full">
    <div className="w-full max-w-6xl md:grid md:grid-cols-2 md:pt-16 md:pb-20">
      <div className="p-6 md:order-2">
        <img
          src="/images/collaborate/collaborate-hero-image.svg"
          alt="Collaborate hero image"
        />
      </div>
      <div className="flex items-center p-6 md:order-1">
        <div>
          <BoldTitle className="mb-6 leading-snug">
            <p>Let’s</p>
            <p className="md:mt-3 text-primary">Collaborate</p>
          </BoldTitle>
          <div className="text-sm leading-7 text-center lg:max-w-sm font-regular md:text-left md:pr-4 text-accent">
            <p>
              At GTAF, we are interested in working with other organisations to
              benefit the Ummah. We believe in collaboration and avoiding
              duplication of efforts to save resources.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default CollaborateHeroSection;
