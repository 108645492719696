import React from 'react';

const ourCriteria = [
  {
    id: 1,
    icon: '/images/collaborate/criteriaIcons/image-1.svg',
    title: 'Islamic Ethics and Values',
    description:
      'Our collaboration is guided by the principles of Islamic ethics and values, including honesty, integrity, and compassion. We strive to uphold these values in all our interactions, both within the organisation and with external partners.',
  },
  {
    id: 2,
    icon: '/images/collaborate/criteriaIcons/image-2.svg',
    title: 'Shared Vision and Goals',
    description:
      'Collaboration thrives when all parties are aligned around a shared vision and common goals. We seek collaborators who share our commitment to serving the Muslim community and advancing our mission through technology. You can check out our mission, vision and core values here.',
  },
  {
    id: 3,
    icon: '/images/collaborate/criteriaIcons/image-3.svg',
    title: 'Equitable Partnerships',
    description:
      'We believe in building partnerships that are equitable, fair, and mutually beneficial. We strive to create opportunities for all collaborators to contribute their expertise, resources, and insights towards our shared objectives.',
  },
  {
    id: 4,
    icon: '/images/collaborate/criteriaIcons/image-4.svg',
    title: 'Continuous Learning and Improvement',
    description:
      'Collaboration provides opportunities for learning and growth. We approach collaboration with a mindset of continuous improvement, seeking feedback, evaluating outcomes, and adapting our approach to enhance effectiveness and efficiency.',
  },
];

const CollaborationCriteria = () => {
  return (
    <div className="w-full bg-primary-0">
      <div className="w-full max-w-6xl px-6 py-16 m-auto md:py-24">
        <div className="w-full max-w-2xl m-auto text-sm text-center md:text-base">
          <p className="leading-7 text-accent">
            We consider some key things while collaborating with other
            non-profit organisations, influencers, or personalities. Some of the
            important ones are-
          </p>
        </div>
        <div className="grid w-full grid-cols-1 gap-6 mt-8 md:gap-8 md:grid-cols-2 md:mt-16">
          {ourCriteria.map((item) => {
            return (
              <div
                key={item.id}
                className="w-full p-6 border bg-neutral rounded-2xl border-primary-2"
              >
                <div>
                  <img
                    className="w-12 h-12 mb-4"
                    src={item.icon}
                    alt={`criteria ${item.title}`}
                  />
                </div>
                <div>
                  <p className="mb-2 font-semibold text-accent">
                    {item.id}. {item.title}
                  </p>
                  <p className="text-sm leading-6 md:text-base text-accent-3">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default CollaborationCriteria;
