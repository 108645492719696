import React from 'react';
import PropTypes from 'prop-types';

function getInputField(inputData, onChange, value, placeholder) {
  switch (inputData.type) {
    case 'text':
      return (
        <input
          className="w-full mb-6 mt-2 rounded-md border-neutral-5 p-6 h-20"
          id={inputData.id}
          type="text"
          onChange={onChange}
          name={inputData.name}
          value={value}
          required={inputData.required}
          placeholder={placeholder}
        />
      );
    case 'email':
      return (
        <input
          className="w-full mb-6 mt-2 rounded-md border-neutral-5 p-6 h-20"
          id={inputData.id}
          type="email"
          onChange={onChange}
          name={inputData.name}
          value={value}
          required={inputData.required}
          placeholder={placeholder}
        />
      );
    case 'textarea':
      return (
        <textarea
          className="w-full mb-6 mt-2 rounded-md border-neutral-5 p-6 resize-y"
          id={inputData.id}
          onChange={onChange}
          name={inputData.name}
          value={value}
          required={inputData.required}
          rows={3}
          placeholder={placeholder}
        />
      );
    case 'select':
      return (
        <div className="relative">
          <select
            className="w-full mb-6 mt-2 rounded-md border-neutral-5 p-6 h-20 form-select relative"
            value={value}
            required={inputData.required}
            onChange={onChange}
            name={inputData.name}
          >
            {inputData.options.map((item) => (
              <option
                key={item.value}
                className="font-regular w-full"
                value={item.value}
              >
                {item.label}
              </option>
            ))}
          </select>
        </div>
      );
    default:
      return null;
  }
}

function FormControl({ data, onChange, value, placeholder }) {
  return (
    <div className="font-regular">
      <label className="my-2 font-regular" htmlFor={data.id}>
        {data.label}
        {data.required ? <sup className="text-secondary">*</sup> : null}
      </label>
      {getInputField(data, onChange, value, placeholder)}
    </div>
  );
}

FormControl.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

export default FormControl;
