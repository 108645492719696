import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const ContactSuccessMessage = ({ message }) => {
  return (
    <div className="flex justify-center px-6 py-6 md:py-16">
      <div className="w-full max-w-3xl pt-20 shadow-2xl rounded-2xl">
        <img className="mx-auto" src="/images/undraw_completed_ngx6.svg" />
        <div
          className="max-w-screen-sm m-auto mt-6 mb-6 leading-6 text-center md:mt-8"
          dangerouslySetInnerHTML={{
            __html: message.replace('\n', '<br>'),
          }}
        ></div>
        <div className="mb-12 text-sm leading-6 text-center font-semiBold text-primary md:mb-52 md:text-base">
          <Link to="/">Go Back to homepage</Link>
        </div>
      </div>
    </div>
  );
};

ContactSuccessMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default ContactSuccessMessage;
