import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { CommonContext } from '../../context';
import Layout from '../../components/layout';
import BoldTitle from '../../components/BoldTitle';
import CollaborationCriteria from '../../components/CollaborationCriteria';
import CollaborationPartners from '../../components/CollaborationPartners';
import ContractForm from '../../components/ContractForm';
import CollaborateHeroSection from '../../components/CollaborateHeroSection';
import PersonalAttributionSection from '../../components/PersonalAttributionSection';
import ContactSuccessMessage from '../../components/ContactSuccessMessage';

export const query = graphql`
  query allCollaborationPartnersQuery {
    allCollaborationPartners {
      nodes {
        id
        title
        description
        link
        imageFile {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    }
    allContact {
      nodes {
        name {
          label
          name
          required
          type
          placeholder
        }
        email {
          label
          name
          required
          type
          placeholder
        }
        subject {
          id
          label
          name
          placeholder
          options {
            label
            value
          }
          required
        }
        message {
          label
          name
          required
          type
          placeholder
        }
      }
    }
    allPersonalAttribution {
      nodes {
        id
        name
        socialLinks {
          linkedIn
          facebook
          twitter
          website
        }
      }
    }
    allMessages {
      nodes {
        contact {
          success
        }
      }
    }
  }
`;

const Collaborate = ({ location, data }) => {
  const collaborationPartners = data.allCollaborationPartners.nodes;
  const personalAttributionData = data.allPersonalAttribution.nodes;
  const successMessage = data.allMessages.nodes[0].contact.success;
  const formInputFields = data.allContact.nodes[0];
  const [formData, setFormData] = useState({
    subject_type: 'collaboration',
  });
  const [showContactSuccessMessage, setShowContactSuccessMessage] =
    useState(false);

  const onSuccessResponse = () => {
    setShowContactSuccessMessage(true);
  };
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        keywords={[`greentech`, `islam`, `non-profit`, `muslims`]}
        title="Collaborate"
        location={location}
      >
        <CollaborateHeroSection />
        <CollaborationCriteria />
        <CollaborationPartners collaborationPartners={collaborationPartners} />
        <PersonalAttributionSection
          personalAttributionData={personalAttributionData}
        />
        <div className="w-full max-w-6xl px-6 py-16 m-auto space-y-16 md:py-24">
          <div className="space-y-4">
            <BoldTitle className="leading-snug md:text-center">
              Get in <span className="text-primary">Touch</span>
            </BoldTitle>
            <div className="w-full max-w-2xl m-auto text-sm text-center md:text-base">
              <p className="leading-7">
                If you are interested in establishing a partnership or
                collaboration with us, please complete the form below and share
                your areas of interest. We are eager to explore potential
                collaborations and create mutual value together.
              </p>
            </div>
          </div>

          {showContactSuccessMessage ? (
            <ContactSuccessMessage message={successMessage} />
          ) : (
            <div className="w-full max-w-3xl p-4 m-auto shadow-2xl bg-neutral-0 rounded-2xl md:p-10">
              <ContractForm
                formData={formData}
                setFormData={setFormData}
                formInputFields={formInputFields}
                onSuccessResponse={onSuccessResponse}
                disabledSubjectSelection
              />
            </div>
          )}
        </div>
      </Layout>
    </CommonContext.Provider>
  );
};

Collaborate.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object.isRequired,
};

export default Collaborate;
