import React from 'react';
import PropTypes from 'prop-types';
import * as Icons from '../custom-icons';
import { cn, getIconName } from '../utils';

const IconComponent = ({ name = '', className, ...props }) => {
  const iconName = getIconName(name);
  // eslint-disable-next-line import/namespace
  const IconSvg = Icons[iconName];

  if (!IconSvg) {
    return null;
  }

  return (
    <IconSvg className={cn('w-6 h-6 inline-block', className)} {...props} />
  );
};

IconComponent.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default IconComponent;
