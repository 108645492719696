import React from 'react';
import { PropTypes } from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

export const Image = ({ imageFile, className, alt, ...rest }) => {
  if (
    imageFile &&
    imageFile.childImageSharp &&
    imageFile.childImageSharp.gatsbyImageData
  ) {
    return (
      <GatsbyImage
        image={imageFile.childImageSharp.gatsbyImageData}
        className={className}
        alt={alt}
        {...rest}
      />
    );
  }

  return <img />;
};
Image.propTypes = {
  imageFile: PropTypes.object,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {
  alt: 'alt-text',
  className: '',
};
