import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function BoldTitle({ children, className }) {
  return (
    <div
      className={clsx(
        'text-3xl md:text-5xl font-extraBold tracking-tight text-center md:text-left text-accent',
        className,
      )}
    >
      {children}
    </div>
  );
}

BoldTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  className: PropTypes.string,
};

BoldTitle.defaultProps = {
  className: '',
};

export default BoldTitle;
