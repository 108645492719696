import PropTypes from 'prop-types';
import React from 'react';

import Header from './header';
import Footer from './Footer';
import SEO from './seo';
import clsx from 'clsx';

function Layout({
  children,
  title,
  keywords,
  description,
  image,
  type,
  additionalData,
  location,
  noindex,
}) {
  // const [showPrayerTime, setShowPrayerTime] = useState(false);

  // useEffect(() => {
  //   if (!localStorage.getItem('hidePrayerTimes')) {
  //     setShowPrayerTime(true);
  //   }
  // });

  return (
    <div className="flex flex-col min-h-screen font-regular">
      <SEO
        title={title}
        keywords={keywords}
        description={description}
        image={image}
        type={type}
        additionalData={additionalData}
        noindex={noindex}
      />
      <Header />

      <main
        className={clsx(
          // showPrayerTime ? 'pt-24' : 'pt-16',
          'pt-16',
          'text-accent font-regular',
        )}
      >
        <div id="section-1" className="h-4" />
        {children}
      </main>
      <Footer location={location} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  keywords: PropTypes.array,
  image: PropTypes.string,
  type: PropTypes.string,
  additionalData: PropTypes.array,
  location: PropTypes.object,
  noindex: PropTypes.bool,
};

export default Layout;
